<template>
  <div class="main">
    <div class="email-settings content-body">
      <a-breadcrumb>
        <a-breadcrumb-item>
          <HomeOutlined />
        </a-breadcrumb-item>
        <a-breadcrumb-item>
          Settings
        </a-breadcrumb-item>
      </a-breadcrumb>
      <a-row class="header">
        <a-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
          <h4>Email Settings</h4>
        </a-col>
      </a-row>
      <a-row class="body">
        <a-tabs v-model:activeKey="activeKey">
          <a-tab-pane key="1" tab="Course Complete">
            <a-form ref="courseCompleteRefForm" :model="courseCompleteForm" :rules="courseCompleteRules" layout="vertical">
              <a-form-item label="Receipients" has-feedback name="receipients">
                <template v-for="(tag, index) in tags" :key="index">
                  <a-tooltip v-if="tag.length > 20" :title="tag">
                    <a-tag :key="tag" :closable="closable" @close="handleClose(tag)">
                      {{ `${tag.slice(0, 20)}...` }}
                    </a-tag>
                  </a-tooltip>
                  <a-tag v-else :key="tag" :closable="closable" @close="handleClose(tag)">
                    {{ tag }}
                  </a-tag>
                </template>
                <a-input v-if="inputVisible" ref="inputRef" type="text" size="small" :style="{ width: '200px' }"
                  v-model:value="inputValue" @blur="handleInputConfirm" @keyup.enter="handleInputConfirm"
                />
                <a-tag v-else @click="showInput" style="background: #fff; border-style: dashed">
                  <plus-outlined /> New Email
                </a-tag>
              </a-form-item>
              <a-form-item label="Subject" required has-feedback name="subject">
                <a-input type="text" placeholder="Enter subject" v-model:value="courseCompleteForm.subject" allow-clear autocomplete="off"/>
              </a-form-item>
              <Editor
                :api-key=timyCloudAPIKey
                v-model="bodyContent"
                :init="{
                  height: 400,
                  menubar: true,
                  plugins: [
                    'save directionality visualblocks visualchars fullscreen image link media  table charmap hr anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons'
                  ],
                  toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist checklist ',
                  quickbars_selection_toolbar: 'bold italic | quicklink h2 h3 blockquote quickimage quicktable',
                  toolbar_mode: 'sliding',
                }"
              />
              <a-form-item>
                <a-button html-type="submit" type="danger" size="large" style="float: right; margin-top: 25px;" @click="updateCourseCompleteTemplate" :loading="loading">
                  Update
                </a-button>
              </a-form-item>
            </a-form>
          </a-tab-pane>
        </a-tabs>
      </a-row>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, UnwrapRef, reactive, toRefs, nextTick } from 'vue'
import { HomeOutlined, PlusOutlined } from '@ant-design/icons-vue'
import { notification } from 'ant-design-vue'
import Editor from '@tinymce/tinymce-vue'

import validate from '../../../services/validator'
import emailSettings from '../../../services/emailSettings'
import commonServices from '../../../services/common'

interface CourseComplete {
  receipients: string;
  subject: string;
  slug: string;
  is_enabled: boolean;
  body?: string;
}

export default defineComponent({
  components: {
    HomeOutlined,
    PlusOutlined,
    Editor
  },
  setup () {
    const timyCloudAPIKey = ref<string>(process.env.VUE_APP_EDITOR_API_KEY)
    const courseCompleteRules = validate.courseCompleteEmail
    const bodyContent = ref<string>('')
    const inputRef = ref()
    const courseCompleteTemplate = ref()
    const loading = ref<boolean>(false)
    const closable = ref<boolean>(true)
    const state = reactive({
      tags: [],
      inputVisible: false,
      inputValue: ''
    })

    const handleClose = (removedTag: string) => {
      const tags = state.tags.filter(tag => tag !== removedTag)
      console.log(tags)
      state.tags = tags
    }

    const showInput = () => {
      state.inputVisible = true
      nextTick(() => {
        inputRef.value.focus()
      })
    }

    const handleInputConfirm = () => {
      const inputValue = state.inputValue
      let tags = state.tags
      if (inputValue && tags.indexOf(inputValue) === -1) {
        tags = [...tags, inputValue]
      }
      console.log(tags)
      Object.assign(state, {
        tags,
        inputVisible: false,
        inputValue: ''
      })
    }
    const notify = async (message: string, description: string, type: string) => {
      notification[type]({
        message,
        description
      })
    }
    const courseCompleteForm: UnwrapRef<CourseComplete> = reactive({
      receipients: '',
      subject: '',
      body: '',
      slug: 'course-complete',
      is_enabled: true
    })
    const updateCourseCompleteTemplate = async () => {
      try {
        if (state.tags.length === 0) {
          await notify('Error', 'Receipients email is required', 'error')
          return
        }
        if (!bodyContent.value) {
          await notify('Error', 'Email body content is required', 'error')
          return
        }
        courseCompleteForm.receipients = state.tags.toString()
        courseCompleteForm.body = bodyContent.value
        console.log(courseCompleteForm)
        await emailSettings.updateTemplate(courseCompleteTemplate.value._id, courseCompleteForm)
        await notify('Success', 'Email template updated successfully', 'success')
      } catch (error) {
        await notify('Error', error.data, 'error')
      }
    }
    const getEmailTemplateBySlug = async (slug) => {
      try {
        const profile = commonServices.getCurrentProfile()
        const responce = await emailSettings.getTemplateBySlug(profile._id, slug)
        if (responce.data.slug === 'course-complete') {
          courseCompleteTemplate.value = responce.data
          Object.assign(courseCompleteForm, {
            subject: responce.data.subject
          })
          Object.assign(state, {
            tags: responce.data.receipients.split(',')
          })
          bodyContent.value = responce.data.body
        }
      } catch (error) {
        await notify('Error', error.data, 'error')
      }
    }
    onMounted(() => {
      console.log('mounted')
      getEmailTemplateBySlug('course-complete')
    })
    return {
      activeKey: ref('1'),
      timyCloudAPIKey,
      courseCompleteForm,
      courseCompleteRules,
      bodyContent,
      ...toRefs(state),
      handleClose,
      showInput,
      handleInputConfirm,
      inputRef,
      closable,
      loading,
      updateCourseCompleteTemplate
    }
  }
})
</script>
<style lang="scss">
.email-settings {
  .header {
    background: #fff;
    padding: 15px 20px;
    margin: 20px 0px 10px;
    border-radius: 8px;
    h4 {
      color: #646464;
      font-family: "TT Norms Pro Medium";
      font-size: 18px;
      text-align: left;
      margin: 0;
    }
  }
  .body {
    background: #ffffff;
    min-height: 200px;
    box-shadow: 0px 5px 10px #00000003;
    border-radius: 8px;
    padding: 20px;
    width: 100%;
    .ant-tabs-line {
      width: 100%;
      text-align: left;
    }
  }
}
</style>

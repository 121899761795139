import axios from 'axios'

export default {
  getTemplateBySlug (clientId, slug) {
    return axios.get(process.env.VUE_APP_EMAIL_TEMPLATE + '/' + clientId + '/' + slug)
  },

  updateTemplate (id, data) {
    return axios.put(process.env.VUE_APP_EMAIL_TEMPLATE + '/' + id, data)
  }
}

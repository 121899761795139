
import { defineComponent, ref, onMounted, UnwrapRef, reactive, toRefs, nextTick } from 'vue'
import { HomeOutlined, PlusOutlined } from '@ant-design/icons-vue'
import { notification } from 'ant-design-vue'
import Editor from '@tinymce/tinymce-vue'

import validate from '../../../services/validator'
import emailSettings from '../../../services/emailSettings'
import commonServices from '../../../services/common'

interface CourseComplete {
  receipients: string;
  subject: string;
  slug: string;
  is_enabled: boolean;
  body?: string;
}

export default defineComponent({
  components: {
    HomeOutlined,
    PlusOutlined,
    Editor
  },
  setup () {
    const timyCloudAPIKey = ref<string>(process.env.VUE_APP_EDITOR_API_KEY)
    const courseCompleteRules = validate.courseCompleteEmail
    const bodyContent = ref<string>('')
    const inputRef = ref()
    const courseCompleteTemplate = ref()
    const loading = ref<boolean>(false)
    const closable = ref<boolean>(true)
    const state = reactive({
      tags: [],
      inputVisible: false,
      inputValue: ''
    })

    const handleClose = (removedTag: string) => {
      const tags = state.tags.filter(tag => tag !== removedTag)
      console.log(tags)
      state.tags = tags
    }

    const showInput = () => {
      state.inputVisible = true
      nextTick(() => {
        inputRef.value.focus()
      })
    }

    const handleInputConfirm = () => {
      const inputValue = state.inputValue
      let tags = state.tags
      if (inputValue && tags.indexOf(inputValue) === -1) {
        tags = [...tags, inputValue]
      }
      console.log(tags)
      Object.assign(state, {
        tags,
        inputVisible: false,
        inputValue: ''
      })
    }
    const notify = async (message: string, description: string, type: string) => {
      notification[type]({
        message,
        description
      })
    }
    const courseCompleteForm: UnwrapRef<CourseComplete> = reactive({
      receipients: '',
      subject: '',
      body: '',
      slug: 'course-complete',
      is_enabled: true
    })
    const updateCourseCompleteTemplate = async () => {
      try {
        if (state.tags.length === 0) {
          await notify('Error', 'Receipients email is required', 'error')
          return
        }
        if (!bodyContent.value) {
          await notify('Error', 'Email body content is required', 'error')
          return
        }
        courseCompleteForm.receipients = state.tags.toString()
        courseCompleteForm.body = bodyContent.value
        console.log(courseCompleteForm)
        await emailSettings.updateTemplate(courseCompleteTemplate.value._id, courseCompleteForm)
        await notify('Success', 'Email template updated successfully', 'success')
      } catch (error) {
        await notify('Error', error.data, 'error')
      }
    }
    const getEmailTemplateBySlug = async (slug) => {
      try {
        const profile = commonServices.getCurrentProfile()
        const responce = await emailSettings.getTemplateBySlug(profile._id, slug)
        if (responce.data.slug === 'course-complete') {
          courseCompleteTemplate.value = responce.data
          Object.assign(courseCompleteForm, {
            subject: responce.data.subject
          })
          Object.assign(state, {
            tags: responce.data.receipients.split(',')
          })
          bodyContent.value = responce.data.body
        }
      } catch (error) {
        await notify('Error', error.data, 'error')
      }
    }
    onMounted(() => {
      console.log('mounted')
      getEmailTemplateBySlug('course-complete')
    })
    return {
      activeKey: ref('1'),
      timyCloudAPIKey,
      courseCompleteForm,
      courseCompleteRules,
      bodyContent,
      ...toRefs(state),
      handleClose,
      showInput,
      handleInputConfirm,
      inputRef,
      closable,
      loading,
      updateCourseCompleteTemplate
    }
  }
})
